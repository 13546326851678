import * as React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  EncompassConfig,
  EncompassHost,
  LOANPASS_PRICE_LOCK_REQUEST_TRANSACTION_TYPE,
} from "../hooks";

interface LoanpassEmbedProps {
  appUrl: string;
  encompassHost: EncompassHost;
  encompassConfig: EncompassConfig;
}

type LoanpassState =
  | { state: "pending" }
  | { state: "logging-in" }
  | { state: "ready" }
  | { state: "submitting" }
  | { state: "lock-success"; isFloat: boolean }
  | {
      state: "error";
      message: string;
      details: string[];
      dismissable: boolean;
    };

type PriceLockResponse =
  | {
      kind: "timeout";
    }
  | {
      kind: "success";
    }
  | {
      kind: "error";
      message: string;
      details: string[];
    };

export const LoanpassEmbed: React.FC<LoanpassEmbedProps> = (props) => {
  const { appUrl, encompassHost, encompassConfig } = props;

  const clientAccessId = encompassConfig.loanpassAuth.loanpassClientAccessId;
  const sessionToken =
    encompassConfig.loanpassAuth.loanpassSessionDetails.sessionToken;

  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  const [loanpassState, setLoanpassState] = useState<LoanpassState>({
    state: "pending",
  });

  const onIframeLoad = useCallback(() => {
    const contentWindow = iframeRef.current?.contentWindow;

    if (contentWindow == null) {
      return;
    }

    contentWindow.postMessage({ message: "connect" }, appUrl);
  }, [appUrl]);

  const onIframeListening = useCallback(() => {
    const contentWindow = iframeRef.current?.contentWindow;

    if (contentWindow == null) {
      console.warn("Could not get iframe content window");
      return;
    }

    switch (loanpassState.state) {
      case "pending":
        contentWindow.postMessage(
          {
            message: "log-in-with-session",
            sessionToken,
            clientAccessId,
          },
          appUrl,
        );
        setLoanpassState({ state: "logging-in" });
        break;
      case "logging-in":
        contentWindow.postMessage(
          {
            message: "set-fields",
            fields: encompassConfig.creditApplicationFieldValues,
          },
          appUrl,
        );

        contentWindow.postMessage(
          {
            message: "enable-price-locking",
          },
          appUrl,
        );

        contentWindow.postMessage(
          {
            message: "enable-float-requests",
          },
          appUrl,
        );

        setLoanpassState({ state: "ready" });
        break;
      case "ready":
        console.warn("Received 'listening' message when state was ready");
        break;
    }
  }, [
    loanpassState,
    clientAccessId,
    sessionToken,
    appUrl,
    encompassConfig.creditApplicationFieldValues,
  ]);

  const onPriceLock = useCallback(
    async (priceLock: LoanpassIFramePriceLockMessage) => {
      let isFloat = priceLock.message === "float-request";
      let requestTypeStr = isFloat ? "float" : "price lock";

      if (loanpassState.state !== "ready") {
        console.warn("LoanPASS state is not ready", { loanpassState });
        return;
      }

      if (encompassHost.transaction == null) {
        console.warn(
          `Failed to get Encompass host context for ${requestTypeStr} request`,
        );
        return;
      }

      setLoanpassState({ state: "submitting" });

      const currentTransaction = await encompassHost.transaction.get();

      let transactionId: string;
      if (currentTransaction.id != null) {
        console.info("Got existing transaction", { currentTransaction });
        transactionId = currentTransaction.id;
      } else {
        const newTransaction = await encompassHost.transaction.create({
          request: {
            type: LOANPASS_PRICE_LOCK_REQUEST_TRANSACTION_TYPE,
            options: {},
            resources: [],
          },
        });

        console.info("Created new transaction", { newTransaction });
        transactionId = newTransaction.id;
      }

      const response = await fetch(
        `${appUrl}/api/vendor-integrations/encompass/price-lock-request`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionToken}`,
          },
          body: JSON.stringify({
            isFloat,
            transactionId,
            executionRequest: priceLock.executionRequest,
            productExecutionResult: priceLock.productExecutionResult,
            priceScenarioResultId: priceLock.scenario.id,
            originatingParty: encompassConfig.originatingParty,
          }),
        },
      );

      if (response.ok) {
        const priceLockResponse: PriceLockResponse =
          (await response.json()) as unknown as PriceLockResponse;

        switch (priceLockResponse.kind) {
          case "success":
            console.info(`Created ${requestTypeStr} request`, {
              loanpassPriceLockResponse: response,
            });
            setLoanpassState({ state: "lock-success", isFloat });
            break;
          case "timeout":
            setLoanpassState({
              state: "error",
              message: "Request timed out. Please try again.",
              details: [],
              dismissable: true,
            });
            break;
          case "error":
            setLoanpassState({
              state: "error",
              message: priceLockResponse.message,
              details: priceLockResponse.details,
              dismissable: true,
            });
        }

        // Close the Encompass embed
      } else {
        let message = `Failed to create ${requestTypeStr} request`;
        console.warn(message, { loanpassPriceLockResponse: response });
        setLoanpassState({
          state: "error",
          message,
          details: [],
          dismissable: true,
        });
      }
    },
    [
      loanpassState,
      encompassHost,
      appUrl,
      sessionToken,
      encompassConfig.originatingParty,
    ],
  );

  const onIframeMessage = useCallback(
    (event: MessageEvent<LoanpassIFrameMessage>) => {
      if (event.origin !== appUrl) {
        console.info("Received message from unexpected origin", event);
        return;
      }

      console.log("Got message", event.data);

      switch (event.data.message) {
        case "listening":
          onIframeListening();
          break;
        case "price-lock":
        case "float-request":
          onPriceLock(event.data);
          break;
      }
    },
    [appUrl, onIframeListening, onPriceLock],
  );

  useEffect(() => {
    window.addEventListener("message", onIframeMessage);

    return () => {
      window.removeEventListener("message", onIframeMessage);
    };
  }, [onIframeMessage]);

  const onDismissError = useCallback(() => {
    if (loanpassState.state !== "error") {
      console.warn("Tried to dismiss error but not error state", {
        loanpassState,
      });
      return;
    }

    setLoanpassState({ state: "ready" });
  }, [loanpassState]);

  return (
    <>
      {loanpassState.state !== "ready" ? (
        <OverlayScreen
          state={loanpassState}
          onDismissError={onDismissError}
          encompassHost={encompassHost}
        />
      ) : null}
      <iframe
        src={`${appUrl}/frame-redirect/${clientAccessId}`}
        ref={iframeRef}
        onLoad={onIframeLoad}
        title="LoanPASS Pricing"
      />
    </>
  );
};

interface OverlayScreenProps {
  state: LoanpassState;
  onDismissError?: () => void;
  encompassHost: EncompassHost | null;
}

export const OverlayScreen: React.FC<OverlayScreenProps> = (props) => {
  const { state, onDismissError, encompassHost } = props;

  const onDismissErrorClick: React.MouseEventHandler<HTMLButtonElement> =
    useCallback(
      (e) => {
        e.stopPropagation();
        e.preventDefault();
        onDismissError?.();
      },
      [onDismissError],
    );

  switch (state.state) {
    case "error":
      return (
        <div className="overlay">
          <div className="dialog error-dialog">
            <div className="dialog-title">Error</div>
            <div className="dialog-content">
              {state.message}
              {state.details.length > 0 && (
                <ul>
                  {state.details.map((detail, key) => (
                    <li key={key}>{detail}</li>
                  ))}
                </ul>
              )}
            </div>
            {state.dismissable ? (
              <button
                className="button"
                style={{ float: "right" }}
                type="button"
                onClick={onDismissErrorClick}
              >
                Dismiss
              </button>
            ) : null}
          </div>
        </div>
      );
    case "lock-success":
      return (
        <div className="overlay">
          <div className="dialog success-dialog">
            <div className="dialog-title">
              {state.isFloat ? "Float" : "Lock"} request complete
            </div>
            <div className="dialog-content">
              Your {state.isFloat ? "float request" : "lock request"} was
              created successfully. Close this dialog to return to Encompass.
            </div>
            <button
              className="button"
              style={{ float: "right" }}
              type="button"
              onClick={() => {
                if (encompassHost !== null) {
                  encompassHost.transaction.close();
                }
              }}
            >
              Close
            </button>
          </div>
        </div>
      );
  }

  return (
    <div className="overlay">
      <p>Loading...</p>
    </div>
  );
};

type LoanpassIFrameMessage =
  | {
      message: "listening";
    }
  | LoanpassIFramePriceLockMessage;

interface LoanpassIFramePriceLockMessage {
  message: "price-lock" | "float-request";
  executionRequest: unknown;
  productExecutionResult: unknown;
  scenario: {
    id: string;
  };
}
